<template>
  <!-- profile css 사용 -->
  <div class="container profile">

    <div class="profile-area">
      <div class="profile-thumbnail ui-bg-img" v-if="artistData.imgUrl1" :style="{ backgroundImage: `url(${$lib.cdnUrl(artistData.imgUrl1)})`, width: '14rem', height: '14rem' }" />
      <icon class="profile-thumbnail" v-else src="img/user/no-profile.png" width="14rem" height="14rem" />
      <div class="profile-text">{{artistData.teamName}}님은<br>헤이비글 <b class="color-lavender">멤버십</b> 회원입니다.</div>
    </div>

    <template v-if="!Object.values(periodAt).some(val => !val)">
      <div class="ui-border-line ui-mt-2" style="height: 10px"></div>

      <div class="profile-info">
        <div class="ui-border-line ui-h-0 ui-mb-1"></div>
        <div class="info-block" v-if="periodAt.start && periodAt.end">
          <ul>
            <li>이용기간</li>
            <li class="float-right">{{periodAt.start}} ~ {{periodAt.end}}</li>
          </ul>
          <div class="ui-border-line ui-mt-1 ui-mb-1"></div>
        </div>
        <!--  -->
        <div class="info-block" v-if="periodAt.next">
          <ul>
            <li>결제 예정일</li>
            <li class="float-right">{{periodAt.next}}</li>
          </ul>
          <div class="ui-border-line ui-h-0 ui-mt-1"></div>
        </div>
      </div>
    </template>

    <div class="ui-border-line ui-mb-6" style="height: 10px"></div>

    <div>
      <h4>멤버십 혜택</h4>
      <icon
        :style="{ margin: '0 -3rem' }"
        src="img/membership/benefits.png"
        :width="`${$store.state.app.width}px`"
        :height="`${$store.state.app.width * 1.22}px`"
        :cover="false"
        />
    </div>

    <div class="ui-border-line ui-h-0 ui-mb-6"></div>
    <footer-box
      v-if="!isMember ? true : artistData.billkey"
      :submit-text="!isMember ? '멤버십 가입하기 >' : '멤버십 해지하기'"
      :submit-cb="() => !isMember ? gotoMembershipInformation() : billCancel()"
      :submit-disabled="false"
    >
    </footer-box>

  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'

export default {
  name: 'Membership',
  components: {
    Icon,
    FooterBox,
  },
  data() {
    return {}
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    artistData() {
      return this.$store.state.user.artistData
    },
    isMember() {
      // if (this.artistData && Number(this.artistData.memberDate) > 0 && Number(this.artistData.memberDate) * 1000 > Date.now()) {
      //   return true
      // }
      return false
    },
    periodAt() {
      let memberDate = Number(this.artistData.memberDate || 0)
      let billDays = 30

      if (!memberDate) {
        return {
          start: null,
          end: null,
          next: null,
        }
      }

      let memberEndAt = this.moment(memberDate * 1000)
      let end = memberEndAt.format('YYYY.MM.DD')
      let next = memberEndAt.add(1, 'd').format('YYYY.MM.DD')
      let start = memberEndAt.add((billDays+1) * -1, 'day').format('YYYY.MM.DD')
      return {
        start,
        end,
        next,
      }
    },
  },
  created() {
    if (this.userData.category === 'client') {
      this.$router.push('/home')
      return
    }

    let p_msg = this.$route.query.p_msg
    if (p_msg !== undefined && p_msg) {
      this.$router.replace('/membership/join', () => {
        setTimeout(() => {
          this.$store.commit('setModal', {
            show: true,
            body: p_msg || '결제가 취소되었습니다.',
            useCancel: false,
          })
        }, 200)
      })
      return
    }

    if (!this.isMember && !this.artistData.billkey) {
      this.$router.replace('/membership/information')
      return
    }
  },
  methods: {
    gotoMembershipInformation() {
      this.$router.push('/membership/information')
    },
    billCancel(confirm = false) {
      if (!confirm) {
        this.$store.commit('setModal', {
          show: true,
          title: '멤버십 해지를 원하시나요?',
          body: `해지시\n다음 달 자동결제가 중지 되며,\n이용 만료일 전까지는\n계속 이용 가능합니다.\n(프로모션 가격으로 재가입 불가)\n\n정말로 해지하시겠어요?`,
          confirm: '바로 해지하기',
          confirmCb: modal => {
            this.billCancel(true).then(() => {
              modal.hide()
            })
          },
          cancel: '닫기',
        })
        return Promise.resolve()
      }

      const req = {
        method: 'put',
        url: `/artist/profile/billCancel`,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$store.commit('setArtistData', {
              billkey: null,
            })
            this.$toast('멤버십이 해지되었습니다.')
            if (!this.isMember) {
              this.$router.push(`/home`)
            }
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.payment-header {
  background-color: $color-deepLavender;
  color: #fff;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 6rem 0 3rem 0;
    font-size: 3.2rem;
    font-weight: 600;
  }
  .text {
    margin: 3rem 0 6rem 0;
    font-size: 2rem;
    line-height: 1.5;
  }
  .image {
    div {
      margin: 0 auto;
    }
  }
}

.profile-area {
  padding: 3rem 0;
  text-align: center;

  .profile-thumbnail {
    margin: 0 auto;
    // width: 10rem;
    // height: 10rem;
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }

  .profile-text {
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 1.4;
    b {
      font-weight: 500;
    }
  }
}

#page > .container.profile {
  .profile-info .info-block ul li {
    &:first-child {
      width: auto;
      color: #464646;
    }
    &:nth-child(2) {
      font-weight: 500;
    }
  }
}
</style>
